import React from "react"
import { graphql } from "gatsby"
import { AppBar, Breadcrumb, Footer, Layout, SEO } from "../components"
import { TemplatesNewsQuery } from "../../types/graphql-types"
import { Slug } from "../views/news"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const title: string = post.frontmatter.title
  const published: Date = new Date(post.frontmatter.published)
  const updated: Date | null = post.frontmatter.updated
    ? new Date(post.frontmatter.updated)
    : null
  const slug: string = post.fields.slug.slice(1).slice(0, -1)
  const description: string = post.excerpt
  const tags: Array<string> = post.frontmatter.tags
  const body: string = post.html

  return (
    <Layout>
      <SEO title={title} description={description} slug={slug} />
      <AppBar />
      <Breadcrumb
        showNav={true}
        itemList={[
          { name: "トップ", slug: "" },
          { name: "ニュース", slug: "news" },
          { name: title, slug: slug },
        ]}
      />
      <Slug
        title={title}
        published={published}
        updated={updated}
        body={body}
        tags={tags}
      />
      <Footer slug={slug} copyright={{ publishDate: updated || published }} />
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query TemplatesNews($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        published(formatString: "YYYY-MM-DD")
        updated(formatString: "YYYY-MM-DD")
        tags
      }
      fields {
        slug
      }
    }
  }
`
