import React from "react"
import { Link } from "gatsby"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import MuiList from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ listItem: { color: "#037dba" } })
)

export type NewsItem = {
  title: string
  published: string
  slug: string
}

type Props = {
  title: string
  newsList: Array<NewsItem>
}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Box mt={4}>
      <Container maxWidth="md">
        <Card variant="outlined">
          <CardHeader title={`#${props.title}`} />
          <MuiList component="nav">
            {props.newsList.map((v, k) => {
              return (
                <React.Fragment>
                  <ListItem
                    component={Link}
                    to={v.slug}
                    className={classes.listItem}
                  >
                    <ListItemText primary={v.title} secondary={v.published} />
                  </ListItem>
                  {k !== props.newsList.length - 1 ? (
                    <Divider variant="fullWidth" component="li" />
                  ) : null}
                </React.Fragment>
              )
            })}
          </MuiList>
        </Card>
      </Container>
    </Box>
  )
}

export const List = Component
