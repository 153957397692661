import React from "react"
import styled from "styled-components"
import { withTheme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"

type BaseProps = { children?: React.ReactNode; className?: string }

const Article: React.FC<BaseProps> = props => (
  <Container maxWidth="sm" className={props.className}>
    {props.children}
  </Container>
)

const Published: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const Tag: React.FC<BaseProps & { name: string }> = props => (
  <a className={props.className}>#{props.name}</a>
)

const Wrapper = withTheme(styled(props => (
  <Container maxWidth="md" className={props.className}>
    {props.children}
  </Container>
))`
  ${({ theme }) => `
    && {
      display: flex;
      padding: 0;

      & .article {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        color: #444;
        flex: 1;
        padding: ${theme.spacing(4)}px;
      }

      & .tag {
        font-size: 0.875rem;
        color: #666;
        background-color: #eee;
      }

      & .nav {
        margin-left: ${theme.spacing(2)}px;
        padding: 0;
        padding-bottom: ${theme.spacing(3)}px;
        padding-top: ${theme.spacing(3)}px;
        position: sticky;
        top: 0;
        align-self: flex-start;
        width: 264px;
        ${theme.breakpoints.down(`sm`)} { display: none }
      }

      & .toc {

        & ul {
          list-style-type: none;
          font-weight: light;
          margin: 0;
          padding: 0;
        }

        & a {
          line-height: 1;
          display: block;
          color: #85837a;
          text-decoration: none;
          padding-left: ${theme.spacing(1)}px;
          padding-right: ${theme.spacing(1)}px;
          padding-bottom: ${theme.spacing(0.5)}px;
          padding-top: ${theme.spacing(0.5)}px;
          &:hover {
            background-color: #e6e5e0;
            color: #333;
          }
        }

        & li,& p {
          margin: 0;
          padding: 0;
          margin-bottom: ${theme.spacing(0.5)}px;
          margin-top: ${theme.spacing(0.5)}px;
        }

        & li ul { padding-left: 1rem }
      }

      & .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem;
        font-feature-settings: palt;
        line-height: 1.4;
      }

      & .published {
        margin-bottom: 1.5rem;
        color: #767676;
        font-size: 0.875rem;
      }

      & .header { margin-bottom: 2.5rem }
      
      & .body {
        & p {
          line-height: 1.9;
          margin: 0;
          & + p { margin-top: 1.5em }
        }
        & h1, & h2, & h3, & h4, & h5, & h6 {
          font-weight: bold;
          line-height: 1.5;
          font-feature-settings: palt;
          margin-top: 3.5rem;
          margin-bottom: 1.5rem;
          cursor: pointer;
          position: relative;
        }
        & h1 {
          font-size: 1.6rem;
          border-bottom: 1px solid #ddd;
          padding-bottom: 0.75rem;
        }
        & h2 { font-size: 1.4rem }
        & h3 { font-size: 1.2rem }
        & h4, & h5, & h6 { font-size: 1.0rem }
        & ul { list-style-type: disc }
        & ul ul { list-style-type: circle, margin: 0 }
        & ul ul ul { list-style-type: square }
        & ol { list-style-type: decimal }
        & ol ol { margin: 0 }
        & ul, & ol {
          padding-left: 1.5rem;
          margin: 1.5rem 0;
          line-height: 1.9;
        }
        & blockquote {
          border-left: 5px solid #ddd;
          color: #767676;
          padding: 1rem;
          padding-right: 0;
          margin: 1.5rem 0;
        }
        & dl { margin: 1.5rem 0 }
        & dt { font-weight: bold }
        & dd { padding-left: 2rem }
        & hr {
          margin: 3rem 0;
          border: 0;
          height: 2px;
          background-color: #ddd;
        }
        & a {
          word-break: break-all;
          color: #037dba;
          text-decoration: none;
        }
        & a:visited { color: #6200ac }
        & details { margin: 1.5rem 0 }
        & table {
          display: block;
          overflow: auto;
          border-left: 1px solid #ddd;
          border-color: #ddd;
          border-collapse: -collapse;
          margin: 0 1.5rem 1.5rem 0;
        }
        & :not(span) + table { margin-top: 1.5rem }
        & span:not(:has(+ table)) { margin-top: 1.5rem }
        & th {
          font-weight: bold;
          background-color: #fff;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 8px 10px;
        }
        & td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 8px 10px;
          max-width: 600px;
        }
        & tr:nth-child(odd) { background-color: #eee }
      }
    }
  `}
`)

type Props = {
  title: string
  published: Date
  updated: Date | null
  tags: Array<string>
  body: string
}

const Component: React.FC<Props> = (props: Props) => {
  const fmt = new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "narrow",
    day: "numeric",
  })

  const strUpdated = props.updated
    ? fmt.format(props.updated)
    : fmt.format(props.published)

  return (
    <Box mt={4}>
      <Wrapper>
        <Article className={"article"}>
          <article>
            <header className={"header"}>
              <Published className={"published"}>{strUpdated} 更新</Published>
              <h1 className={"title"}>{props.title}</h1>
              <div className={"tagList"}>
                {props.tags.map((v, k) => (
                  <Tag key={k} name={v} className={"tag"} />
                ))}
              </div>
            </header>
            <section
              className={"body"}
              dangerouslySetInnerHTML={{ __html: props.body }}
            />
          </article>
        </Article>
      </Wrapper>
    </Box>
  )
}

export const Slug = Component
